export default {
  blogerName: 'MAL200',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@BoomBoom123/streams',
    },
    {
      name: 'youtube',
      url: 'http://www.youtube.com/@Maliboo_Stream',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@BoomBoom_MaxWin',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/maliboo325',
    },
    {
      name: 'telegram',
      url: 'https://t.me/boomboom325',
    },
    {
      name: 'telegram',
      url: 'https://t.me/MaliBoo325',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/sweetboomamza/',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/cd364c35c',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c25f8f558',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/cc75170aa',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/cced50970',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c84fba07d',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c151403c7',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsopard.com/c3ff1741e',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cca403a34',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/cbfc5b5fc',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cb3540c6f',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>MAL200</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'MAL200',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
